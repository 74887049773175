.unpurchasedIcon {
    position: absolute;
    top: 16px;
    right: 23px;
    width: 20px;
    height: 29px;
    background-color: #BFBFBF;
    mask: url('../../assets/Lock Icon.svg') no-repeat center;
    transition: all .3s ease-in-out;
    -webkit-mask-size: contain;
    mask-size: contain;
}
.mediaItemTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.mediaItemContainer:hover {
    will-change: transform !important;
    outline: #FFFFFF 3px solid !important;
    transform: scale(101%) !important;
    transform-origin: center center
}
.mediaItemContainer:hover .overlay {
    opacity: 0.75 !important;
}
.mediaItemContainer:hover .liveIndicatorContainer {
    width: 79px !important;
}
.mediaItemContainer:hover .upcomingIndicatorContainer {
    width: 131px !important;
}
.mediaItemContainer:hover .unpurchasedBackground {
    backdrop-filter: none !important;
    --webkit-backdrop-filter: none !important;
    background: none !important;
}
.mediaItemContainer:hover .indicatorText {
    opacity: 1 !important;
}
.mediaItemContainer:hover .unpurchasedOverlay {
    opacity: 1 !important;
}
.mediaItemContainer:hover .unpurchasedText {
    opacity: 1 !important;
    transform: translateX(2%) !important;
}
.mediaItemContainer:hover .unpurchasedIcon {
    background-color: #FFFFFF !important;
}
.mediaItemContainer:hover .unpurchasedContainer {
    opacity: 0 !important;
    /*border-top: 108px solid transparent !important;*/
}
.mediaItemContainer:hover .upcomingText {
    opacity: 1 !important;
}
.mediaItemContainer:hover .progressBarContainer {
    transform: translateY(-11px) !important;
    width: 90% !important;
}
.mediaItemContainer:hover .progressBarText {
    opacity: 1 !important;
}
@media only screen and (max-width: 800px) {
    .mediaItemWrapper {
        margin: 10px 10px 12px 0 !important;
    }
    .mediaItemContainer {
        height: 84px !important;
        width: 151px !important;
    }
    .mediaItemContainer:hover .progressBarContainer {
        transform: translateY(0%) !important;
        width: 100% !important;
    }
    .mediaItemContainer:hover .progressBarText {
        opacity: 0 !important;
    }
    .mediaItemTitle {
        margin-top: 6px !important;
        margin-left: 2px !important;
        width: 151px !important;
        height: 32px !important;
        font-size: 13px !important;
    }
    .overlay {
        display: none !important;
    }
    .unpurchasedOverlay {
        display: none !important;
    }
    .unpurchasedContainer {
        /*border-top: 54px solid rgb(64, 64, 64) !important;
        border-left: 54px solid transparent !important;*/
    }
    .unpurchasedIcon {
        top: 6px !important;
        right: 8px !important;
        width: 13px !important;
        height: 13px !important;
    }
    .mediaItemContainer:hover .unpurchasedIcon {
        background-color: #BFBFBF !important;
    }
    .mediaItemContainer:hover .unpurchasedContainer {
        opacity: 0 !important;
        /*border-top: 54px solid rgb(64, 64, 64) !important;*/
    }
    .indicatorText {
        display: none !important;
    }
    .liveIndicatorContainer {
        width: 29px !important;
        height: 29px !important;
    }
    .upcomingIndicatorContainer {
        width: 29px !important;
        height: 29px !important;
    }
    .indicator {
        width: 11px !important;
        height: 11px !important;
        left: 8px !important;
    }
    .mediaItemContainer:hover .liveIndicatorContainer {
        width: 31px !important;
    }
    .mediaItemContainer:hover .upcomingIndicatorContainer {
        width: 31px !important;
    }
    .mediaItemContainer:hover .unpurchasedBackground {
        background: rgba(0, 0, 0, 0.5) !important;
        backdrop-filter: blur(10px) !important;
        --webkit-backdrop-filter: blur(10px) !important;
    }
    .unpurchasedText {
        display: none !important;
    }
    .upcomingText {
        display: none !important;
    }
}
