.tooltip-top::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-top-start::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -5px;
    left: 15px;
    margin: 0 auto;
    transform: rotate(45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-top-end::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -5px;
    right: 15px;
    margin: 0 auto;
    transform: rotate(45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-bottom::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(225deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-bottom-start::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: -5px;
    left: 15px;
    margin: 0 auto;
    transform: rotate(225deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-bottom-end::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: -5px;
    right: 15px;
    margin: 0 auto;
    transform: rotate(225deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-left::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    right: -5px;
    top: calc(50% - 4px);
    margin: 0 auto;
    transform: rotate(-45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-left-start::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    right: -5px;
    top: 10px;
    margin: 0 auto;
    transform: rotate(-45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-left-end::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    right: -5px;
    bottom: 10px;
    margin: 0 auto;
    transform: rotate(-45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-right::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: -5px;
    top: calc(50% - 4px);
    margin: 0 auto;
    transform: rotate(135deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-right-start::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: -5px;
    top: 0;
    margin: 0 auto;
    transform: rotate(135deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.tooltip-right-end::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: -5px;
    bottom: 0;
    margin: 0 auto;
    transform: rotate(135deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

@media only screen and (max-width: 800px) {
    .tooltip {
        font-size: 13px !important;
    }
}