.tooltipText::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 31px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}